const sk = {
  global: {
    my_resume: 'Moje CV',
    resume: 'CV',
    ok: 'Ok',
    or: 'alebo',
    and: 'a',
    step: 'Krok',
    yes: 'Áno',
    no: 'Nie',
    employment: 'Zamestnanie',
    from: 'Od',
    to: 'Do',
    start: 'Začiatok',
    end: 'Koniec',
    new: 'Nový',
    more_info: 'Viac info',
    trade: 'Živnosť',
    experience: 'Skúsenosť',
    place: 'Miesto',
    suggested: 'Navrhované',
    titles: {
      all_done: 'Hotovo',
      fill_out_form: 'Vyplniť formulár',
      sent: 'Odoslané',
      added: 'Pridané',
      saved: 'Uložené',
      welcome: 'Vaša registrácia bola úspešná! Vitajte v Koderii',
    },
    actions: {
      create_resume: 'Vytvoriť',
      save: 'Uložiť',
      recommend: 'Odporučiť',
      share: 'Zdieľať',
      upload_file: 'NAHRAŤ SÚBOR',
      send: 'Odoslať',
      continue: 'Pokračovať',
      back: 'Späť',
      select: 'Vyberte',
      delete: 'Vymazať',
      delete_account: 'Zrušiť účet',
      add_post: 'Pridať príspevok',
      open: 'OTVORIŤ',
      upload_files: 'NAHRAŤ SÚBORY',
      IMPORT: 'IMPORTOVAŤ',
      add_more: 'Pridať ďalšie',
      add: 'Pridať',
      add_another: 'Pridať ďalšiu',
      copy: 'Kopírovať',
      complete: 'Dokončiť',
      resend: 'Odoslať znovu',
      show_less: 'Zobraziť menej',
      unlock: 'Odomknúť',
      change_image: 'Zmeniť obrázok',
      change_logo: 'Zmeniť logo',
      publish: 'Publikovať',
      back_to_home_page: 'Spať na hlavnú stránku',
      edit: 'Upraviť',
      download: 'Stiahnuť',
      close: 'Zatvoriť',
      register: 'Zaregistrovať',
      confirm: 'Potvrdiť',
      more: 'Viac',
    },
    time: {
      monthly: 'Mesačné | Mesačne',
      annual: 'Ročné',
      today: 'Dnes',
      hour: 'hod',
      year: 'Rok',
      years: 'rok | roky | rokov',
      month: 'Mesiac',
      months: 'mesiac | mesiace | mesiacov',
      several_weeks: 'o niekoľko týždňov',
      months_3: '3 mesiace',
      months_6: '6 mesiacov',
      manday: 'manday',
      now: 'Teraz',
      later: 'Neskôr',
      january: 'január',
      february: 'február',
      march: 'marec',
      april: 'apríl',
      may: 'máj',
      june: 'jún',
      july: 'júl',
      august: 'august',
      september: 'september',
      october: 'október',
      november: 'november',
      december: 'december',
    },
    errors: {
      unknown: 'Vyskytla sa chyba. Skúste to znova neskôr, prosím.',
      already_exists: 'E-mailovú adresu už používa iný účet.',
      auth_expired_action_code: 'Platnosť tokenu vypršala.',
      auth_invalid_action_code: 'Neplatný token.',
      auth_user_disabled: 'Účet bol zablokovaný.',
      auth_user_not_found: 'Neplatné prihlasovacie údaje.',
      auth_weak_password: 'Heslo nie je dostatočne silné.',
      auth_email_already_in_use: 'E-mailovú adresu už používa iný účet.',
      auth_invalid_email: 'Neplatný e-mail.',
      auth_operation_not_allowed: 'Operácia nie je povolená.',
      auth_account_exists_with_different_credential: 'Účet už existuje s inými prihlasovacími údajmi.',
      auth_invalid_credential: 'Neplatné prihlasovacie údaje.',
      auth_wrong_password: 'Neplatné heslo.',
      auth_invalid_phone_number: 'Neplatné telefónne číslo.',
      auth_missing_phone_number: 'Chýba telefónne číslo.',
      auth_cancelled_popup_request: 'Kontextové okno bolo zatvorené.',
      auth_popup_blocked: 'Kontextové okno bolo zablokované.',
      auth_popup_closed_by_user: 'Kontextové okno bolo zatvorené pred dokončením operácie.',
      auth_user_token_expired: 'Platnosť tokenu vypršala.',
      auth_invalid_api_key: 'Neplatný API kľúč.',
      auth_network_request_failed: 'Vyskytla sa chyba siete. Skúste to znovu neskôr.',
      auth_requires_recent_login: 'Vyžaduje sa aktuálne prihlásenie.',
      auth_too_many_requests: 'Príliš veľa požiadavok v krátkom čase.',
      auth_invalid_password: 'Neplatné heslo.',
      auth_email_already_exists: 'E-mailová adresa sa už používa.',
      auth_phone_number_already_exists: 'Telefónne číslo sa už používa.',
      auth_insufficient_permission: 'Nemáte dostatočné oprávnenia.',
      auth_internal: 'Došlo k chybe. Prosím, skúste to znovu neskôr.',
      permission_denied: 'Nemáte dostatočné oprávnenia.',
      phone_codes_error: 'Pri načítavaní telefónnych kódov krajín sa vyskytol problém. Skúste to znova neskôr.',
      unexpected_error: 'Vyskytla sa neočakávaná chyba. Skúste to znova neskôr.',
      error_404: 'Pri načítavaní údajov „{name}“ sa vyskytol problém. Skúste to znova neskôr.',
    },
    inputs: {
      examples: {
        name: 'Ján',
        email: 'jan.drahy@email.com',
        searchJob: 'Hľadaj Frontend, Linux, SQL...',
      },
      firstname_surname: 'Meno a priezvisko',
      email: 'E-mail',
      first_name: 'Meno',
      last_name: 'Priezvisko',
      password: 'Heslo',
      city: 'Mesto',
      experiences: 'Skúsenosti',
      phone: 'Telefónne číslo',
      profession: 'Profesia',
      technology: 'Technológie',
      name: 'Názov',
      company: 'Spoločnosť',
      position: 'Pozícia',
      title: 'Názov',
      search_expertise: 'Vyhľadať technológiu',
    },
    form_rules: {
      length_6: '6 a viac znakov',
      non_empty_field: 'Vyplňte prosím toto pole',
      valid_email: 'Vyplňte prosím platnú e-mailovú adresu',
      phone_number: 'Telefónne číslo musí byť vo formáte +421 9XX XXX XXX',
      min_3: 'Aspoň 3',
      not_valid_email: 'E-mail musí byť platnou e-mailovou adresou.',
      password_length: 'Heslo musí obsahovať najmenej 6 znakov.',
      min_1_technology: 'Prosím, zadajte aspoň 1 technológiu.',
      event_image_required: 'Obrázok podujatia je povinný.',
      event_name_required: 'Názov podujatia je povinný.',
      event_name_min_3_characters: 'Názov podujatia musí mať aspoň 3 znaky.',
      event_datetime_manadatory: 'Dátum a čas podujatia je povinný.',
      link_format: 'Odkaz nemá správny formát (príklad: https://koderia.sk/podujatia).',
      event_reference_required: 'Odkaz na podujatie je povinný.',
      venue_required: 'Miesto konania podujatia je povinné.',
      event_description_required: 'Popis podujatia je povinný.',
      organizer_logo_required: 'Logo organizátora je povinné.',
      organizer_name_required: 'Názov organizátora je povinný.',
      organizer_website_required: 'Webstránka organizátora je povinná.',
      organizer_description_required: 'Popis organizátora je povinný.',
      organizer_description_long: 'Popis organizátora je príliš dlhý.',
      event_end_before_start: 'Koniec podujatia nesmie byť pred jeho začiatkom',
      required_field: 'Povinné pole',
      first_name_filled: 'Krstné meno musí byť vyplnené.',
      last_name_filled: 'Priezvisko musí byť vyplnené.',
      password_min_six_characters: 'Heslo musí obsahovať najmenej 6 znakov.',
      accept_terms_conditions: 'Musíte akceptovať zmluvné podmienky.',
      no_data: 'Nie sú k dispozícii žiadne údaje',
    },
    form_errors: {
      check_entered_information: 'Vyskytla sa chyba. Skontrolujte zadané informácie.',
      upload_photo_max_size_5mb: 'Fotografiu sa nepodarilo nahrať, maximálna veľkosť je 5 MB.',
      enter_name: 'Prosím, zadajte vaše meno.',
      enter_last_name: 'Prosím, zadajte vaše priezvisko.',
      enter_email: 'Prosím, zadajte email',
      select_phone_code: 'Prosím, vyberte predvoľbu svojej krajiny.',
      enter_phone_number: 'Prosím, zadajte vaše telefónne číslo.',
      valid_phone_number: 'Prosím, zadajte platné telefónne číslo.',
      forgot_profession: 'Zabudli ste vyplniť svoju profesiu?',
      select_available_from_date: 'Prosím, vyberte dátum dostupnosti',
      no_data_available: 'Žiadne dáta k dispozícii',
      select_utm_source: 'Prosím, vyberte ako Ste sa o nás dozvedeli',
      select_non_it_registration_reason: 'Prosím, vyberte dôvod registrácie',
    },
    deprecated: {
      agree_with_processing_personal_data_according_to_current_working: 'Súhlasím so spracovaním osobných údajov podľa aktuálneho znenia',
      privacy_policy_small: 'zásad o ochrane súkromia',
      confirm_that_koderia_contact_me_in_future: 'Potvrdzujem, že ma Koderia v budúcnosti môže kontaktovať',
    },
    expertiseTypes: {
      'PROGRAMMING_LANGUAGE': 'Programovací jazyk',
      'FRAMEWORK': 'Framework',
      'DATABASE': 'Databáza',
      'LIBRARY': 'Knižnica',
      'CLOUD': 'Cloud',
      'TOOLS': 'Nástroje',
      'METHODOLOGY': 'Metodológia',
      'DEVOPS': 'Dev / Ops',
      'OTHER': 'Iné',
    },
    expertiseLevel: {
      '0-1': 'Menej ako 1 rok',
      1: '1 rok',
      2: '2 roky',
      3: '3 roky',
      4: '4 roky',
      '5-10': '5 – 10 rokov',
      '10+': 'Viac ako 10 rokov',
    },
    utm_source: {
      referral: 'Odporúčanie',
    },
    non_it_registration_reasons: {
      student: 'Vzdelávam sa v oblasti IT a v budúcnosti by som sa chcel stať IT špecialistom',
      no_contact: 'Som IT človek, ale nechcem aby ste ma kontaktovali s ponukami práce',
      try_tools: 'Chcem len vyskúšať Koderia nástroje (napr. Mzdová kalkulačka, Moje CV)',
    },
  },
  ad: {
    not_interested_in_any_position: 'Nezaujala Vás žiadna pozícia? Povedzte nám, čo musí spĺňať ideálny projekt a my ho nájdeme.',
    do_you_know_anyone_might_be_interested_in_this_position:
      'Poznáte niekoho, koho by mohla táto pozícia zaujímať? Odporučte nám ho/ju a získavajte províziu',
    every_day: 'každý deň',
    you_can_also_have_commission_from_this_position: 'Aj z tejto pozície môžete mať províziu',
    do_you_know_anyone_might_be_interested_in_position: 'Poznáte niekoho, koho by mohli tieto pozície zaujímať? Odporučte nám ho/ju a získavajte províziu',
  },
  auth: {
    titles: {
      create_account: 'Vytvoriť účet',
      account: 'Účet',
    },
    subTitles: {
      account_advantages: 'Užívajte si bezplatne všetky výhody Koderia účtu',
      verification: 'Potrebné overenie účtu',
    },
    actions: {
      sign_in_simple: 'Prihlásiť',
      sign_up_simple: 'Zaregistrovať',
      sign_in: 'Prihlásiť sa',
      sign_up: 'Zaregistrovať sa',
      logout: 'Odhlásiť sa',
      create_new_account: 'Vytvorte si nový.',
      create_account: 'Vytvoriť účet',
      forgot_password: 'Zabudnuté heslo',
      sign_in_here: 'Prihláste sa tu.',
      sign_up_here: 'Zaregistrujte sa',
      verify: 'Poslať overenie',
    },
    descriptions: {
      check_email: 'Skontrolujte si svoju e-mailovú schránku, pre istotu aj priečinok spam. Ak sa Vám prihlásiť nepodarí, napíšte nám na',
      dont_have_account: 'Nemáte ešte svoj účet?',
      email_associated_with_koderia: 'Ak je uvedená e-mailová adresa priradená k účtu na Koderii, pošleme na ňu odkaz na obnovenie hesla.',
      check_confirmation_email: 'Skontrolujte si svoju e-mailovú schránku, na ktorú sme Vám zaslali potvrdzovací email.',
      check_welcome_email: 'Skontrolujte si svoju e-mailovú schránku, na ktorú sme Vám zaslali uvítacií email.',
      already_have_account: 'Máte už vytvorený účet?',
      advantage_1: 'Prístup k hodnotným službám Koderia',
      advantage_1_message: 'Obľúbené služby Adekvátny plat, Mzdová kalkulačka alebo Koderia Extra na jednom mieste',
      advantage_2: 'Jednoduchá reakcia na IT projekty',
      advantage_2_message: 'Na IT projekty a služby Koderia reagujete vďaka predvyplneným formulárom jednoducho jedným kliknutím',
      advantage_3: 'Personalizované pracovné ponuky',
      advantage_3_message: 'Pracovné ponuky prispôsobené a zoradené na základe Vašej špecializácie',
      advantage_4: 'Transparentná spolupráca',
      advantage_4_message: 'Prehľad o Vašich údajoch a využitých službách, ku ktorým sa môžete kedykoľvek vrátiť alebo ich upraviť',
      verification_dialog_message: 'Pre prístup do tejto časti overte svoju e-mailovú adresu. Na váš registrovaný e-mail sme poslali overovací odkaz. Pokračujte kliknutím na odkaz v e-maile. Ak ste e-mail nedostali, skontrolujte si priečinok so spamom alebo požiadajte o nový overovací e-mail.',
      non_it_description: 'Ak vyberiete možnosť, že nie ste z oblasti IT, nebudeme Vás kontaktovať ani Vám ponúkať prácu na projektoch. Ak sa však v budúcnosti rozhodnete zmeniť svoje zameranie, môžete to kedykoľvek upraviť vo svojom profile. Ak ste si len nesprávne vybrali profesiu, alebo sa neviete správne zaradiť, vyberte prosím možnosť OTHER.',
    },
    inputs: {
      remember_login: 'Zapamätať prihlásenie',
    },
  },
  cookie: {
    description:
      'Webová stránka Koderia.sk používa k poskytovaniu služieb a analýze návštevnosti súbory cookies. Pokračovaním v prezeraní súhlasíte s ich používaním.',
    more_information: 'Viac informácií.',
    button: 'Rozumiem a súhlasím',
  },
  navigation: {
    titles: {
      job_offer: 'Pracovné ponuky',
      legal_information: 'Právne informácie',
      social_network: 'Sociálne siete',
      menu: 'Menu',
      next: 'Ďalšie',
    },
    home: 'Domov',
    jobs: 'Práca',
    carrier: 'Kariera',
    events: 'Podujatia',
    blog: 'Blog',
    newsfeed: 'Newsfeed',
    dictionary: 'Slovník',
    what_is_koderia: 'Čo je Koderia?',
    adequate_salary: 'Adekvátny plat',
    calculator: 'Mzdová kalkulačka',
    work_comparison: 'Porovnanie práce',
    work_types_comparison: 'Porovnanie foriem práce',
    koderia_referral: 'Koderia Referral',
    koderia_extra: 'Koderia Extra',
    privacy_policy: 'Zásady ochrany osobných údajov',
    resume: 'CV',
    create_resume: 'Vytvoriť CV',
    terms_conditions: 'Zásady o ochrane súkromia',
    koderia_dictionary: 'Koderia Slovník',
    about_us: 'O nás',
    contact_us: 'Kontakt',
    policy: 'Zasady',
    settings: 'Nastavenia',
    create_new_koderia_biography: 'Vytvoriť nový Koderia CV',
  },
  homepage: {
    home: 'Hlavná stránka',
    title:
      'Moderné a prehľadné CV. <span class=\'tw-text-green-light md:tw-text-white\'>Pre<br class=\'tw-hidden md:tw-inline\' /> všetkých v IT. <br class=\'md:tw-hidden\' /> Zadarmo.</span>',
    subtitles: {
      jobs: 'Najnovšie joby',
      newsfeed: 'Svetový newsfeed',
      events: 'Nadchádzajúce podujatia',
      blogs: 'Najnovšie blogy',
    },
    description:
      'Vytvorte si v priebehu pár minút responzívne CV, ktoré<br class=\'tw-hidden md:tw-inline\' /> môžete kedykoľvek <b>upraviť</b>, <b>zdieľať</b> či <b>vytlačiť</b>.',
    actions: {
      jobs: 'Všetky joby',
      newsfeed: 'Všetky príspevky',
      events: 'Všetky podujatia',
      blogs: 'Všetky blogy',
      learn_more: 'Zistiť viac',
    },
  },
  project: {
    title: 'Plánuje váš kolega, priateľ či známy zmeniť prácu?',
    description: 'Odporučte ho na niektorý z našich projektov a v prípade úspešnej dohody získavajte províziu každý deň.',
    actions: {
      learn_more: 'Zistiť viac',
      reset_filters: 'Resetovať filtre',
    },
    detail: {
      status: {
        not_available: 'Tento projekt už bol obsadený alebo nie je aktuálny.',
        new: 'Nové',
        remote: 'Remote',
        already_responded: 'Na túto ponuku ste už reagovali',
      },
      subtitles: {
        position: 'Popis pozície',
        hr_need_info: 'Potrebujete viac info?',
        interest_contacts: 'Kontaktné informácie a CV',
        good_news: 'Dobré správy',
        interest_upload_resume: 'Nahrať CV teraz?',
      },
      descriptions: {
        useful_for_you: 'Podľa info vo Vašom profile a CV by sa Vám tento job mohol hodiť. Nezmeškajte príležitosť pracovať na tom, čo vám ide.',
        create_resume: 'Vytvorte si Koderia CV a zistite, ako sa vaše skúsenosti zhodujú s požiadavkami na túto pracovnú ponuku.',
        team_contacts_you: 'V priebehu nasledujúcich dní Vás kontaktuje člen/ka nášho tímu a dohodne sa s Vami na ďalšom postupe.',
        modern_resume: 'Moderný a prehľadný CV pre <br class="md:tw-hidden" /> každého v IT.',
        hr_contact_directly: 'Obráťte sa priamo na našich recruiterov, ktorí vám radi povedia viac.',
        interest_team_contacts_you: 'Ďakujeme za Váš záujem, čoskoro Vás budeme kontaktovať.',
        interest_recommended_upload: 'Odporúčame nahrať teraz, budeme ho potrebovať.',
      },
      actions: {
        respond_to_offer: 'Reagovať na ponuku',
        all_jobs: 'Všetky joby',
      },
      salary: 'Plat',
      location: 'Lokalita',
      views: 'zobrazenie | zobrazenia | zobrazení',
      candidates: 'kandidát | kandidáti | kandidátov',
      requirements: 'Požiadavky',
      employer_benefits: 'Výhody zamestnávateľa',
      project_duration: 'Dĺžka projektu',
      duration_extension: 's možnosťou predĺženia',
      start_date: 'Dátum nástupu',
      type: 'Typ',
      tpp: 'TPP',
      contract: 'Kontrakt',
      agreement: 'Dohoda',
      project_language: 'Jazyk na projekte',
      similar_job: 'Podobné joby',
      free: 'Zadarmo',
      salary_per_contract: 'Plat na kontrakt',
      salary_per_tpp: 'Plat na TPP',
      top_offer: 'Top ponuka',
      email_reaction_to: 'Reakcia na',
      email_body: 'Prosím, nezabudnite pridať CV',
    },
  },
  event: {
    title: 'Nadchádzajúce podujatia',
    subtitles: {
      online: 'Online',
      slovakia_czech: 'Slovensko a Česko',
      event_info: 'Info o podujatí',
      organizer: 'Organizátor',
    },
    descriptions: {
      upload_cover_image: 'Nahrajte titulný obrázok',
      basic_information: 'Základné informácie o podujatí ako názov, miesto, popis a čas konania podujatia.',
      organizer_information: 'Stručné informácie o organizátorovy podujatia',
      no_scheduled_events: 'Momentálne nie sú plánované žiadne podujatia.',
    },
    detail: {
      action: 'Pridať do kalendára',
    },
    inputs: {
      event_link: 'Odkaz na podujatie',
      list: 'Popis',
      ticket_purchase_link_optional: 'Odkaz pre zakúpenie lístkov (Nepovinné)',
      webpage: 'Webstránka',
      description_of_organizer: 'Popis organizátora',
      date_and_time: 'Dátum a čas',
    },
    cover_image: 'Titulný obrázok',
    date_and_time: 'Dátum a čas',
    online_event: 'Online podujatie',
    organize_question: 'Organizujete podujatie?',
  },
  blog: {
    title: 'Koderia Blog',
    subtitles: {
      more_articles: 'Ďalšie články',
    },
    description: 'Píšeme o témach, ktoré rezonujú IT svetom',
    actions: {
      read: 'Prečítať',
      more_articles: 'Ďalšie články',
    },
    detail: {
      subtitle: 'Mohlo by Vás zaujímať',
    },
  },
  newsfeed: {
    title: 'Newsfeed',
    action: 'Ďalšie články',
  },
  questionaire: {
    title: 'Adekvátny plat',
    subtitles: {
      experience: 'Ako sú ocenené skúsenosti,<br class="tw-hidden lg:tw-block" /> ktoré ste doteraz nadobudli?',
      paid_jobs: 'Adekvátne zaplatené joby',
      evaluate_resume: 'Vyhodnotiť CV',
      choose_method: 'Vyberte si spôsob vyhodnotenia',
      about_you: 'Informácie o Vás',
      master_technologies: 'Technológie, ktoré ovládate',
      total_experience: 'Celkový počet rokov praxe',
      master_experience: 'Počet rokov praxe v',
      leadership_experience: 'Boli ste niekedy Team Leader alebo Scrum Master?',
      domain_experience: 'Máte skúsenosti s konkrétnou doménou?',
      domain_experience_examples: '(napr. Bankovníctvo, Medicína, Telekomunikácie, …)',
      software_architect_experience: 'Pracovali ste ako softvérový architekt?',
      type_of_tester: 'Aký typ testera ste?',
      ci_experience: 'Máte skúsenosti s CI technológiami Docker, Jenkins, GIT, ...?',
      performance_experience: 'Máte skúsenosti s programovaním performance testov?',
      job_listing_title: 'Pracovné pozície',
    },
    descriptions: {
      salary_evaluate:
        'Váš plat vyhodnocujeme na základe skúseností, ktoré ste vo svojej kariére nadobudli. Vyhodnotenie prebieha nahraním CV alebo vyplnením formulára. Obe možnosti sú vysoko presné.',
      experience_evaluate_by_team: 'Vaše skúsenosti vyhodnotí člen/ka Koderia tímu.',
      experience_evaluate_by_system: 'Vaše skúsenosti vyhodnotí náš sofistikovaný systém.',
      experience_evaluate_by_pro_team: 'Vaše skúsenosti vyhodnotí člen/ka Koderia PRO tímu.',
      first_step: 'V prvom kroku je dôležité zistiť, ktoré technológie ovládate a počet rokov Vašej praxe.',
      second_step: 'V druhom kroku uveďte doplňujúce informácie o svojej doterajšej kariére.',
      third_step: 'Nakoniec nám nechajte svoje meno a e-mail, na ktorý pošleme výsledok formulára.',
      answer_questions: 'Odpovedzte na nasledujúce otázky.',
      thank_you: 'Ďakujeme za Váš záujem, čoskoro Vás budeme kontaktovať.',
    },
    actions: {
      all_jobs: 'Všetky joby',
      upload_resume: 'Nahrať CV',
    },
    inputs: {
      choose_position: 'Vyberte si pozíciu',
    },
    programmer: 'Programátor',
    tester: 'Tester',
    it_specialist: 'IT špecialista',
    project_manager: 'Projektový manažér',
    analyst: 'Analytik',
    automated_tester: 'Automatizovaný tester',
    manual_tester: 'Manuálny tester',
  },
  calculator: {
    title: 'Mzdová kalkulačka',
    subtitles: {
      possible_earnings:
        'Pracujete na trvalý pracovný pomer?<br class="tw-hidden lg:tw-block" />Zistite, koľko by ste zarábali na <b>živnosť</b> alebo <b>s.r.o.</b>',
      find_out_more: 'Zistite viac, <span class="tw-text-green">zadarmo</span>.',
      billing_options: 'Možnosti fakturácie',
      price_md: 'Cena za človekodeň',
      price_md_sup: '(Man-day Rate)',
      recommended_job_offers: 'Pracovné ponuky pre teba',
    },
    actions: {
      calculate: 'Vypočítať',
      find_job: 'Nájdi prácu',
      get_hired: 'Zamestnaj sa',
    },
    descriptions: {
      main:
        'Nástroj Mzdová kalkulačka vypočítava sumu, ktorú by ste mali fakturovať, ak pracujete na živnosť alebo S.R.O. z TPP tak, <b>aby sa náklady zamestnávateľa nezvýšili.</b>',
      current_income: 'Vaše aktuálne príjmy a odvody na trvalom pracovnom pomere.',
      more_after_login: 'Radi vám <strong class="tw-font-bold">zdarma</strong> ukážeme viac, po príhlásení.',
      more_after_verify: 'Radi vám <strong class="tw-font-bold">zdarma</strong> ukážeme viac, po overení Vášho emailu.',
      your_md_rate: 'Aká je vaša cena za človekodeň (Man-day Rate)',
      earn_for_trade: 'Koľko budete zarábať na živnosť',
      earn_on_sro: 'Koľko budete zarábať na s.r.o.',
      fees_and_taxes: 'Aké odvody a dane budete platiť',
      option_for_you: 'Ktorá možnosť sa pre vás oplatí viac',
      net_monthly_income: 'Toto je suma, ktorú by ste mali dostať pri prechode na faktúru.',
    },
    inputs: {
      gross_monthly_income: 'Zadajte svoj hrubý mesačný príjem (min 700 €)',
    },
    taxes: 'dane',
    net_monthly_income: 'Čistý mesačný príjem',
    months_worked_in_year: 'Odpracované mesiace v roku',
    super_gross_annual_salary: 'Superhrubá ročná mzda',
    employer_levies: 'Odvody zamestnávateľa',
    employee_contributions: 'Odvody zamestnanca',
    invoice_for_year: 'Faktúrované za rok',
    deductions_together: 'Odvody spolu',
    income_tax: 'Daň z príjmu',
    price_md: 'Cena za človekodeň',
    invoice_md: 'Suma, ktorú by ste si mali fakturovať za 1 odpracovaný deň.',
    trade_first_year: 'Živnosť v prvom roku',
    trade_2nd_and_next_year: 'Živnosť v 2. a ďalšom roku',
    average_net_monthly_income: 'Priemerný čistý mesačný príjem',
    taxes_and_levies: 'Dane a odvody',
    health_insurance_contributions: 'Odvody do zdravotnej poistovne',
    social_insurance_contributions: 'Odvody do sociálnej poistovne',
    together: 'Spolu',
    billing_options_learn_more: 'Viac o možnostiach fakturácie sa dozviete v',
    billing_options_learn_more_link: 'tomto článku.',
    company_sro: 'Spoločnosť (s.r.o.)',
    company_tax: 'Dane spoločnosti',
    dividend_tax: 'Daň z dividend',
    total_taxes: 'Dane spolu',
    overview: 'Prehľad',
    price_of_labor: 'Cena práce',
    super_gross_salary: 'Superhrubá mzda',
    gross_salary: 'Hrubá mzda',
    gross_salary_after_tax: 'Hrubá mzda po zdanení',
    net_income: 'Čistý príjem',
    average_invoice: 'Priemerná faktúra',
    company_profit: 'Zisk spoločnosti',
    gross_tax_base: 'Hrubý daňový základ',
    flat_cost: 'Paušálne náklady',
    health_insurance: 'Zdravotné poistenie',
    social_insurance: 'Sociálne poistenie',
    view_detailed_taxes_and_levies: 'Zobraziť podrobné dane a odvody',
  },
  work_comparison: {
    title: 'Porovnanie foriem práce',
    subtitle: 'Zistite, ktorá z foriem <br class="tw-hidden lg:tw-block" />práce je pre vás najvhodnejšia',
    subheadings: {
      accounting: 'Účtovníctvo',
      establishment: 'Založenie',
      taxes: 'Dane',
      non_taxable_part: 'Nezdaniteľná časť',
      levies: 'Odvody',
    },
    descriptions: {
      main: 'Detailné porovnanie trvalého pracovného pomeru, <br class="tw-hidden lg:tw-block" />živnosti a spoločnosti s ručením obmedzeným (s.r.o.)',
      taxes_follows_law: 'Zo znenia zákona vyplýva, že {percentage}% sadzba dane bude od roku {year} uplatniteľná daňovníkom s obratom do {grossTax} €.',
      annual_amount_non_taxable_part:
        'Ročná výška nezdaniteľnej časti základu dane na daňovníka v roku {year} je {untaxablePartOfTaxBase} € ak základ dane nižší alebo rovný ako {minimumTaxBase} €. Od tejto sumy až kým je základ dane vyšší ako suma {milionaireTaxBase} € klesá nezdaniteľná časť až k nule.',
      social_insurance_not_paid:
        'Sociálne poistenie sa neplatí. Zdravotné poistenie sa platí minimum. V ďalších rokoch sociálne odvody platí, ak v predošlom roku prekročil hranicu príjmov {minimumYearWage} € (za rok {year}), odvody sa zvyšujú spolu s príjmom; do zdravotnej poistovne platí aj ked nič nezarobí (minimálna výška odvodov je {minimumMedicalTax} € mesačne).',
      manager_one_person:
        'Ak je konateľ jedna osoba a nevypláca si mzdu, nemusí platiť odvody do sociálnej poisťovne a do zdravotnej platí ako samoplatca; ak je však konateľ aj zamestnancom, musí platiť odvody aj do zdravotnej, aj do sociálnej poisťovne.',
    },
    permanent_employment: 'Trvalý pracovný pomer',
    self_employed_person: 'Samostatne zárobkovo činná osoba',
    limited_liability_company: 'Spoločnosť s ručením obmedzeným',
    simple: 'jednoduché',
    double: 'podvojné',
    income_tax: 'daň z príjmu',
    dividend_tax: 'daň z dividend',
    other: 'Iné',
    holiday: 'dovolenka',
    sick_leave: 'PN',
    retirement_savings: 'dôchodkové sporenie',
    low_contributions_low_pension: 'nízke odvody = nízky dôchodok',
    benefits: 'Výhody',
    disadvantages: 'Nevýhody',
    regular_income: 'pravidelný príjem',
    financial_benefits: 'finančné aj nefinančné benefity',
    get_loan: 'jednoduchšie získanie pôžičky',
    lower_salary: 'menší plat',
    working_hours: 'presne stanovený pracovný čas',
    notice_period: 'výpovedná lehota',
    fast_establishment_process: 'rýchly a lacný proces založenia',
    possible_flat_rate_expenses: 'možnosť využitia paušálnych výdavkov',
    social_contributions_exemption: 'oslobodenie od platenia sociálnych odvodov počas 1. roka',
    unlimited_liability: 'neobmedzené ručenie',
    health_social_contributions_amount: 'zdravotné a sociálne odvody závisia od výšky príjmu',
    less_credible_image: 'menej dôveryhodný imidž (pre obchodných partnerov môže budiť dojem malého podnikateľa)',
    limited_liability: 'obmedzené ručenie',
    credible_image: 'lepší a dôveryhodnejší imidž (budí dojem väčšieho a serióznejšieho podnikateľa pre obchodných partnerov a potenciálnych zamestnancov)',
    equal_income_tax: 'rovná daň z príjmu bez ohľadu na dosiahnuté príjmy',
    expensive_founding_process: 'dlhší a drahší proces založenia vrátane potreby vypracovania zakladateľských dokumetov',
    demanding_administration: 'náročnejšia a drahšia administratíva (povinné vedenie podvojného účtovníctva), 50-70 eur/mesiac',
    termination_process: 'komplikovanejší proces ukončenia',
    graph_net_income: 'Grafové porovnanie čistého príjmu',
    company: 'Spoločnosť',
    trade_after_first_year: 'Živnosť po prvom roku',
    temporary_incapacity_for_work: 'Dočasná pracovná neschopnosť',
    gross_salary: 'Hrubá mzda (Fakturovaná suma)',
    chart_popup_more_info: 'Pre presnejšie dáta navštívte mzdovú kalkulačku',
  },
  extra: {
    title: 'Extra',
    subtitles: {
      your_idea: 'Povedzte nám, ako má vyzerať <br class="tw-hidden lg:tw-block" />práca podľa Vašich predstáv',
      project_information: 'Informácie o projekte',
      information_about_you: 'Informácie o Vás',
      preferred_technologies: 'Preferované technológie',
    },
    descriptions: {
      no_interested_position: 'Nezaujala Vás žiadna pozícia? Vyplňte krátky formulár a my pre Vás budeme hľadať projekt podľa zvolených požiadaviek',
      will_be_in_touch: 'Ďakujeme, čoskoro budeme v kontakte. Zatiaľ si môžete pozrieť čo je nové na domovskej stránke.',
    },
    inputs: {
      date_of_start: 'Dátum začiatku',
      phone_number_optional: 'Telefónne číslo (Nepovinné)',
      question_or_additional_info: 'Otázky alebo dodatočné info (Nepovinné)',
    },
    junior_less_than_2_years: 'Junior (menej ako 2 roky)',
    medior_2_4_years: 'Medior (2 – 4 roky)',
    senior_5_and_more: 'Senior (5 a viac rokov)',
  },
  referral: {
    title: 'Koderia Referral',
    subtitles: {
      colleague_planning: 'Plánuje Váš kolega, priateľ <br class="tw-hidden lg:tw-block" />či známy zmeniť prácu?',
      how_it_works: 'Ako to funguje?',
      forms_of_payment: 'Formy vyplácania',
      one_time_commission: 'jednorázová provízia',
      worked_day: 'za každý odpracovaný deň',
      ready_to_collaborate: 'Pripravený na spoluprácu?',
      candidate_information: 'Informácie o kandidátovi',
      information_about_you: 'Informácie o Vás',
      you_should_know: 'Mali by ste vedieť',
    },
    descriptions: {
      recommend_projects: 'Odporučte ho na niektorý z našich projektov a v prípade úspešnej dohody získavajte províziu každý deň.',
      friend_looking:
        'Jednoducho. Vy nám dáte vedieť o svojom priateľovi, kolegovi alebo známom, o ktorom viete, <br class="tw-hidden lg:tw-block" />že hľadá prácu v IT. My ho umiestnime na niektorý z našich projektov a provízia je vaša.',
      fill_short_form: 'Vyplníte krátky formulár, na základe ktorého Vášho známeho kontaktujeme.',
      interview_with_you: 'Nasleduje pohovor u nás alebo u klienta, po ktorom zistíme, či sme sa našli.',
      concluding_contract: 'Uzatvorenie zmluvy s úspešným kandidátom a vyplatenie provízie.',
      one_time_commission:
        'Jednorázovú províziu vyplácame po prvých troch odpracovaných mesiacoch. 5 € za každý odpracovaný deň vyplácame mesačne, od začiatku do konca projektu. V prípade projektov kratších ako 6 mesiacov vyplácame 500 € alebo tiež 5 € za každý odpracovaný deň. Províziu vyplácame aj v prípade, že sa s kandidátom dohodneme na inej pozícii.',
      recommendation_touch_soon: 'Ďakujeme za Vaše odporúčanie, čoskoro budeme v kontakte. Zatiaľ si môžete pozrieť čo je nové na domovskej stránke.',
      recommend_to_position: 'Odporúčate známeho na konkrétnu pozíciu?',
      preferred_type_of_provision: 'Aký typ provízie preferujete? (Nepovinné)',
      change_provision_type: 'Vybraný typ provízie môžete kedykoľvek zmeniť.',
      forms_of_reward:
        'Formy odmeny sú: jednorázová provízia vo výške <b>1000 €</b>, ktorú vyplácame po prvých troch odpracovaných mesiacoch alebo <b>5&nbsp;€ za každý odpracovaný deň</b>, od začiatku do konca projektu (vyplácané mesačne).',
      shorter_projects: 'V prípade projektov kratších ako 6 mesiacov vyplácame 500 € alebo tiež 5 € za každý odpracovaný deň.',
      receive_commission: 'Províziu dostanete aj v prípade, že sa s kandidátom dohodneme na inej pozícii.',
      data_deleted_from_db: 'Vaše údaje budú vymazané z našej databázy.',
      recommended_you:
        'Vás odporúčil na jednu z našich voľných pozícií. V prípade, že nepoznáte osobu, ktorá nám poslala Vaše informáce, kliknite na tlačidlo nižšie a vymažte svoje údaje.',
    },
    inputs: {
      current_position: 'Súčasná pozícia',
    },
    one_time_1000: '1 000 € jednorázovo',
    per_day_worked_5: '5 € za každý odpracovaný deň',
    record_not_found: 'Záznam sa nenašiel.',
  },
  koderia_about: {
    title: 'Čo je Koderia?',
    descriptions: {
      unique_project:
        'Koderia je unikátny projekt, kde si na svoje prídu všetci programátori či programátorky, nadšenci IT, študenti a študentky technických predmetov, ale i ľudia, ktorým je blízky svet IT. Čo všetko Koderia ponúka zistíte nižšie.',
      homepage:
        'Domovská stránka Koderie je odrazovým mostíkom pre všetky funkcie a možnosti, ktoré ponúkame. Nájdete na nej najnovšie pracovné ponuky, príspevky zo svetových portálov, IT podujatia na Slovensku ale aj online zo sveta a mnoho iného.',
      job_section:
        'V sekcii práca nájdete najnovšie pracovné ponuky. Sprostredkúvame prácu do firiem, ktoré sú lídrami vo svojom obore na Slovensku, v Česku a Rakúsku. Pod záštitou máme už desiatky kóderov a kóderiek.',
      looking_for_colleague:
        'Hľadá si váš kolega, priateľ či známy prácu v IT? Dajte nám o ňom vedieť a v prípade vzájomnej dohody a následnej spolupráce sa s vami rozdelíme s províziou. Môžete tak získavať 5 € denne alebo jednorázovú sumu 1000 €.',
      interested_offer:
        'Nezaujala vás žiadna pracovná ponuka? Vyplnením krátkeho formuláru na našej stránke nám môžete dať vedieť, o akú pozíciu máte záujem a my vás budeme kontaktovať v prípade, ak sa o takejto ponuke dozvieme.',
      modern_resume:
        'Moderný a prehľadný CV, ktorý dokážete jedným klikom zdieľať či vytlačiť, by mal byť v súčasnosti samozrejmosťou pre každého, hlavne v IT sektore.',
      salary_tool: 'Jednoduchý nástroj, ktorý určuje výšku adekvátneho platu na základe skúseností.',
      trade_to_sro: 'Vypočítanie fakturovanej sumy pri prechode z TPP na živnosť alebo s.r.o.',
      blog: 'Blogu patrí samostatná stránka. Píšeme v ňom o témach, ktoré rezonujú IT svetom a o myšlienkach, s ktorými sa stotožňujeme.',
    },
  },
  profile: {
    title: 'Koderia účet',
    subtitles: {
      email_settings: 'E-mailové nastavenia',
      invalid_link: 'Neplatný odkaz',
      thank_you: 'Ďakujeme',
      linkedin_import_data: 'Importovanie údajov z LinkedIn',
      account_settings: 'Nastavenia účtu',
      newsletter: 'Newsletter',
      basic_settings: 'Základné nastavenia',
      delete_account: 'Naozaj chcete zrušiť účet?',
      confirm_email_address: 'Potvrdenie e-mailovej adresy',
      finish_signup: 'Skôr ako začnete, <br class="tw-hidden lg:tw-block" />povedzte nám <br class="tw-hidden lg:tw-block" />niečo o sebe.',
    },
    descriptions: {
      invalid_link: 'Odkaz nie je platný alebo jeho platnosť vypršala',
      recommended_salary: 'Váš plat by nemal byť nižší ako',
      find_out_recommended_salary:
        '<span>Vyplňte sekciu <a href="#" @click.prevent="$emit(\'go-to-experience\')" class="tw-text-black">skúsenosti</a> a zistite,</span><br /><span>koľko by ste mali zarábať.</span>',
      approval_of_contribution: 'O schválení príspevku Vás budeme informovať. Svoje pridané príspevky následne nájdete v menu, v sekcii „Pridané“.',
      share_original_posts: 'Zdieľajte s nami originálne príspevky, ktoré vás na internete zaujali.',
      no_posts: 'Zatiaľ nemáte pridané žiadne príspevky.',
      linkedin_open_personal_data: 'Otvorte si podstránku o svojich osobných údajoch',
      linkedin_use_your_data: 'How LinkedIn uses your data',
      linkedin_download_large_data: '<p class="step-info">Zvoľte možnosť <strong>Download larger data archive…</strong></p>',
      linkedin_request_archive: '<p class="step-info">Následne kliknite na <strong>Request archive</strong></p>',
      linkedin_unzip_file: 'Súbor, ktorý si stiahnete, rozbaľte a nahrajte nám súbory',
      linkedin_provide_file: 'Berte prosím na vedomie, že LinkedIn vám súbor nemusí poskytnúť ihneď.',
      import_click: 'Následne kliknite na <strong>Importovať</strong>.',
      private_resume: 'CV je nastavený ako súkromný. Zmeniť to môžete v',
      token_resume_no_password: 'CV heslo nie je nastavené. Nastaviť ho môžete v',
      private_resume_change: 'nastaveniach, časť Súkromie',
      what_is_experience_chart: 'Čo predstavuje tento graf a ako funguje?',
      fill_in_experience_chart: 'Pre správne zobrazenie grafu, vyplňte svoje skúsenosti.',
      resume_link: 'Na tomto odkaze nájdete svoj CV vy a ľudia, s ktorými ho zdieľate.',
      resume_language: 'Jazyk, v ktorom je zobrazený CV.',
      resume_social_contact: 'Zobrazovanie e-mailu, telefónu a webstránky alebo sociálnej siete v CV.',
      resume_availability: 'Zvoľte pre koho chcete svoj CV sprístupniť.',
      account_password_not_allowed: 'Nezadávajte heslo od svojho účtu!',
      resume_password_viewing_only: 'Toto heslo slúži len pre zobrazenie CV a bude dostupné iba ľuďom, ktorým sa ho rozhodnete poskytnúť.',
      digital_experience_graph: 'Zobrazovanie grafu skúsenosti v digitálnej verzii CV.',
      positions_based_on_technologies: 'Nové pozície podľa technológií, ktoré ste uviedli v nastaveniach účtu.',
      sending_news: 'Všetko, čo sa je na našom portáli nové. Posielame priebežne – maximálne 1x za mesiac.',
      new_blog_articles: 'Nové články z nášho blogu – maximálne 1x za týždeň.',
      account_cancellation_undone: 'Zrušenie účtu sa nedá vrátiť. Ste si istý, že chcete svoj účet zrušiť?',
      no_posts_saved: 'Zatiaľ nemáte uložené žiadne príspevky.',
      no_events_saved: 'Zatiaľ nemáte uložené žiadne podujatia.',
      no_job_offer_saved: 'Zatiaľ nemáte uložené žiadne pracovné ponuky.',
      confirm_email_address:
        'Na e-mailovú adresu <span class="tw-font-bold">{email}</span> sme Vám poslali odkaz na potvrdenie e-mailu. Skontrolujte si svoju e-mailovú schránku, pre istotu aj priečinok spam.',
      basic_information: 'Základné informácie, ktoré musí mať každé CV',
      about_me_input: '2-3 krátke vety o tom, kto ste a na čo sa špecializujete',
      employment: 'Uveďte informácie o svojom zamestnaní. Začnite od najnovšieho alebo aktuálneho.',
      stack: 'Zoberte si až 9 špecializácií, ktoré sú pre vás najcennejšie a máte v tom najviac skúseností.',
      stack_notify: 'Už sme pre vás vybrali niektoré technológie z predchádzajúcej sekcie 😎',
      education: 'Poskytujte informácie o svojej strednej, vysokej alebo vysokej škole. Začnite od najnovších.',
      education_diploma_placeholder: 'Majster inžinierstva alebo čo, neviem neštudoval som...',
      references: 'Pridajte informácie o projektoch, na ktorých ste pracovali a sú už online.',
      references_description_input: 'Napíšte krátky popis projektu alebo ponechajte prázdne.',
      certificates: 'Ak máte nejaké certifikáty, ktoré chcete ukázať, toto je to miesto.',
      certificates_description_input: 'V prípade potreby napíšte krátky popis',
      languages: 'Dajte svojmu budúcemu zamestnávateľovi vedieť, akými jazykmi hovoríte.',
      technology_input: 'Vyberte technológie, ktorý ste používali počas zamestnania.',
      employment_description_input: 'Napíšte asi 3-7 viet o svojej práci a o tom, za čo ste boli zodpovední.',
      education_description_input: 'Napíšte asi 3-6 viet o svojom štúdiu.',
      cv_generation_in_progress: 'Váš životopis sa generuje. Po dokončení dostanete životopis do vašej e-mailovej schránky: <b>{email}</b>',
    },
    actions: {
      fill_position: 'Doplňte svoju pozíciu',
      respond_to_offer: 'Reagovať na ponuku',
      move_down: 'Posunúť nižšie',
      move_up: 'Posunúť vyššie',
      delete_photo: 'Odstrániť fotografiu',
      change_photo: 'Zmeniť fotku',
      upload_photo: 'Nahrať fotku',
      delete_custom_cv: 'Odstrániť CV',
      change_custom_cv: 'Zmeniť CV',
      upload_custom_cv: 'Nahrať CV',
      go_to_events: 'Prejsť na podujatia',
      show_posts: 'Zobraziť príspevky',
      find_job: 'Nájsť prácu',
      send_cv_by_email: 'Poslať emailom',
      show_resume: 'Pozrieť CV',
      create_koderia_cv: 'Vytvoriť Koderia CV',
      pick_previous_tech: 'Vyberte technológie z predchádzajúcuj práce',
    },
    inputs: {
      dominated_technologies: 'Ovládané technológie',
      bio: 'Bio',
      current_email: 'Aktuálny e-mail',
      new_email: 'Nový e-mail',
      new_password: 'Nové heslo',
      url_link: 'URL odkaz',
      description: 'Popis',
      country: 'Krajina',
      education_diploma: 'Dosiahnuté vzdelanie / Diplom',
      school_university: 'Škola/Univerzita',
      faculty: 'Fakulta',
      technology_software_activity: 'Technológia, softvér, činnosť…',
      numbers_of_years_of_experience: 'Počet rokov praxe',
      language: 'Jazyk',
      level: 'Úroveň',
      link: 'URL',
      web_social_network: 'Web / Sociálna sieť',
      about_me: 'Krátke info o tebe',
      new_jobs: 'Nové pracovné pozície',
      koderia_news: 'Koderia novinky',
      profession_finish_up: 'Čo najlepšie vystihuje vašu profesiu?',
      country_finish_up: 'Odkiaľ pochádzate?',
      technologies_finish_up: 'Ktoré technológie používate?',
      technologies: 'Technológie',
      non_it: 'Nie som IT človek',
      source_about_us: 'Ako ste sa o nás dozvedeli?',
      non_it_registration_reason: 'Prečo ste sa rozhodli zaregistrovať?',
    },
    posts: 'Príspevky',
    data_in_cv: 'Údaje',
    basic_information: 'Základné informácie',
    education: 'Vzdelanie',
    school: 'Škola',
    references: 'Referencie',
    reference: 'Referencia',
    technologies: 'Technológie',
    years_of_experience: 'Roky skúsenosti',
    certificates: 'Certifikáty',
    certificate: 'Certifikát',
    languages: 'Jazyky',
    experience_chart: 'Graf skúseností',
    import_your_data_from: 'Importujte svoje dáta z',
    linkedin: 'LinkedInu',
    link: 'Odkaz',
    language: 'Jazyk',
    app_language: 'Jazyk aplikácie',
    contact_information: 'Kontaktné údaje',
    privacy: 'Súkromie',
    graph: 'Graf',
    public: 'Verejný',
    registered_users: 'Registrovaní užívatelia',
    private: 'Súkromný',
    accessible_with_password: 'Prístupný s heslom',
    job_looking_finish_up: 'Hľadáte si v súčasnosti prácu?',
    job_timeframe_finish_up: 'V akom časovom horizonte si plánujete hľadať prácu?',
    job_listing_title: 'Pracovné pozície',
  },
  gdpr: {
    title: 'Ochrana súkromia',
    default_agree: 'Súhlasím so spracovaním osobných údajov podľa aktuálnych',
    personal_data: 'zásad ochrany osobných údajov',
    descriptions: {
      privacy_policy_tooltip:
        'Kliknutím na zaškrtávacie políčko „Súhlasím so spracovaním osobných údajov podľa aktuálnych zásad ochrany osobných údajov“ udeľujete Prevádzkovateľovi súhlas so spracovaním Vašich osobných údajov. Účelom získavania Vašich osobných údajov je poskytnúť Vám na základe Vašej žiadosti informáciu o adekvátnej výške platu na konkrétnej zvolenej pozícii. Svoj súhlas so spracovaním osobných údajov udeľujete na dobu 3 rokov a máte právo ho kedykoľvek odvolať na e-mailovej adrese <u>kontakt@koderia.sk</u>. Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania osobných údajov založenom na súhlase pred jeho odvolaním.',
      receive_job_offers: 'Nesúhlasím s občasným zasielaním na mieru šitých pracovných ponúk',
      contact_tooltip:
        'Kliknutím na zaškrtávacie políčko „Súhlasím so spracovaním osobných údajov podľa aktuálnych zásad ochrany osobných údajov“ udeľujete Prevádzkovateľovi súhlas so spracovaním Vašich osobných údajov a s ich uchovávaním v internej databáze. Účelom spracovania Vašich osobných údajov je nájdenie vhodnej pracovnej pozície. Svoj súhlas so spracovaním osobných údajov udeľujete na 5 rokov a máte právo ho kedykoľvek odvolať na našej na e-mailovej adrese <u>kontakt@koderia.sk</u>. Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania osobných údajov založenom na súhlase pred jeho odvolaním.',
      register_account_tooltip:
        'V rámci registrácie kliknutím na zaškrtávacie políčko „Súhlasím so spracovaním osobných údajov podľa aktuálnych zásad ochrany osobných údajov“ udeľujete súhlas so spracovaním osobných údajov a s ich uchovávaním na dobu existencie účtu. O zrušenie účtu môžete kedykoľvek požiadať a svoj súhlas so spracovaním osobných údajov odvolať na našej na e-mailovej adrese <u>kontakt@koderia.sk</u>. Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania osobných údajov založenom na súhlase pred jeho odvolaním. Na základe právneho základu oprávneného záujmu Vám budeme v súvislosti s vytvorením účtu zasielať náš newsletter s voľnými pracovnými pozíciami a inými informáciami, ktoré by pre Vás mohli byť zaujímavé. V prípade, že si zasielanie newslettra neželáte, môžete jeho zasielanie kedykoľvek zrušiť v nastaveniach účtu, sekcia e-mailové nastavenia.',
      newsletter_personalized_offers: 'Súhlasím so zasielaním personalizovaných pracovných ponúk a noviniek',
      newsletter_contact_me_tooltip:
        'Kliknutím na zaškrtávacie políčko „Súhlasím, aby ma Kodeira v budúcnosti kontaktovala a zasielala mi zaujímavé novinky“ udeľujete Prevádzkovateľovi súhlas s uchovávaním osobných údajov v internej databáze na účel zasielania marketingových ponúk. Účelom spracovania a uchovávania Vašich osobných údajov je zasielať Vám marketingové ponuky, ktoré by pre Vás mohli byť zaujímavé. Svoj súhlas so spracovaním osobných údajov týkajúci sa odberu newslettra udeľujete na dobu 5 rokov a máte právo ho kedykoľvek odvolať na našej Webovej stránke prostredníctvom e-mailovej adresy <u>kontakt@koderia.sk</u>. Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania osobných údajov založenom na súhlase pred jeho odvolaním. Po uplynutí doby udelenia súhlasu alebo po odvolaní súhlasu so spracovaním osobných údajov bude Váš CV vymazaný.',
      project_interest_agree_to_contact: 'Súhlasím, aby ma Koderia v budúcnosti kontaktovala a zasielala mi zaujímavé novinky',
    },
  },
  404: {
    title: 'Stránka nebola nájdená',
    subtitle: 'Hľadali ste svoj nový job?',
    action: 'Všetky joby',
  },
  about: {
    title: 'Team profesionálov',
    subtitles: {
      way_to_success: 'Naša cesta k úspechu',
      outsourcing_pros: 'Výhody outsoucingu s Koderiou',
      contractors_db: '12+ tisíc kontraktorov v našej internej databáze',
      personal_approach: 'Osobný prístup',
      selection_process: 'Kvalitný výberový proces',
      contact_us: 'Kontaktujte nás',
    },
    descriptions: {
      hero_outsourcing: `
        Sme hrdými zástupcami Koderia brandu a naša snaha spočíva v tom, aby sme vám poskytli najlepších
        developerov, ktorí presne vyhovujú vašim potrebám pre projekty. Sprostredkúvame prácu do firiem, ktoré sú
        <span class="tw-font-bold ">lídrami vo svojom obore</span> na Slovensku, v Česku a Rakúsku. Pod záštitou
        máme už desiatky programátorov a programátoriek.
      `,
      objectify: `
        Naša spoločnosť je tiež súčasťou renomovanej firmy Objectify, čo nám umožňuje spojiť našu flexibilitu a
        osobný prístup s podporou a zázemím softwarehousu.
      `,
      years_of_experience: `
        Rokov skúseností<br class="tw-hidden lg:tw-block" />
        na trhu
      `,
      satisfied_it: `
        Spokojných IT<br class="tw-hidden lg:tw-block" />
        špecialistov
      `,
      candidates_count: `
        Mesačný nárast<br class="tw-hidden lg:tw-block" />
        kandidátov v databáze
      `,
      satisfied_clients: `
        Spokojných<br class="tw-hidden lg:tw-block" />
        klientov
      `,
      delivered_candidates: `
        Úspešne dodaných<br class="tw-hidden lg:tw-block" />
        mandayov
      `,
      rocket: {
        salary_calculator: 'Mzdová kalkulačka',
        salary_calculator_uses: 's viac ako 500tis. použitiami',
        turnover: 'Prekonanie 2 miliónového obratu',
        resume_formation: 'Vznik',
        resume: 'Koderia životopisu',
        resume_sign_in: 'a možnosti prihlásenia sa na portál',
        brand_formation: 'Vznik brandu',
        salary_calculator_launch: 'Spustenie bezkonkurenčnej',
        salary_calculator_2: 'Mzdovej kalkulačky',
        adequate_salary_launch: 'Štart portálu Kóderi Kóderom / Spustenie služby',
        adequate_salary: 'Adekvátny plat',
      },
      contractors_db: `
        Pričom ku každému programátorovi máme skills rozdelené do kompetenčných centier, kde vieme presne zacieliť pracovné ponuky.
        Naši špecialisti majú širokú škálu odborných zručností, vrátane software developmentu, bezpečnosti dát, cloudových
        technológií a mnoho ďalších oblastí IT.
      `,
      personal_approach: `
        Ku každému kontraktorovi / programátorovi pristupujeme ako keby robil v našej firme. Veríme, že náš tím profesionálnych IT
        špecialistov vám môže poskytnúť nielen vysoko kvalitné IT služby, ale aj pocit práce s výnimočným tímom, ktorý je plne
        oddaný úspechu vašich projektov.
      `,
      selection_process: `
        Každý člen tímu prešiel dôkladným výberovým procesom a má skúsenosti s prácou na rôznych projektoch v rôznych odvetviach.
        Poskytujeme profesionálnych IT špecialistov s bohatými skúsenosťami a odbornými znalosťami na projekty našich klientov.
      `,
      feedback: `
        Vaša spätná väzba je pre nás veľmi dôležitá, pretože nám umožňuje zlepšovať naše služby a prispievať k
        vytváraniu lepšej komunity pre programátorov.
      `,
      count_with_us: 'Sme tu, aby sme vám pomohli s informáciami a našli riešenia, ktoré vyhovujú vašim potrebám.',
      form_sent_thank_you: 'Ďakujeme za správu!',
    },
    inputs: {
      name: 'Meno a priezvisko *',
      phone: 'Telefón *',
      url: 'Spoločnosť alebo Linkedin profil',
      message: 'Správa *',
      gdpr: 'Súhlasím so spracovaním osobných údajov',
    },
  },
  resume: {
    title: 'CV',
    subtitles: {
      modern_clear_cv: 'Moderný a prehľadný CV<br class="tw-hidden lg:tw-block" /> pre každého v IT.',
      modern_clear_cv_free: 'Zadarmo.',
      tailored_cv: 'CV prispôsobené<br/> vaším potrebám',
      unique_graph: 'Skúsenosti zobrazené v prehľadnom grafe',
      ready_to_print: 'CV pripravené na tlač aj bezpečné zdieľanie',
      experience_salary: 'Suma, ktorú by ste mali zarábať na základe skúseností',
      reviews: 'Hodnotenia',
      start_new_cv: 'Začnite písať svoje nové CV',
      cv_is_secure: 'CV je zabezpečený',
    },
    subheadings: {
      experience_chart: 'Graf skúseností',
      print_share: 'Zdieľanie aj tlač',
      adequate_salary: 'Adekvátny plat',
      responsive_design: 'Responzívny dizajn',
      english_language: 'Anglický jazyk',
      password_protected: 'Chránený heslom',
      it_sector_oriented: 'Orientovaný na IT sektor',
      linkedin: 'LinkedIn Import',
      easy_edit: 'Jednoduchá úprava',
      faq_1: 'Je vytvorenie CV naozaj zadarmo?',
      faq_2: 'Ako môžem zdieľať svoje CV?',
      faq_3: 'Je CV dostupné v iných jazykoch?',
      faq_4: 'Ako si do CV importujem údaje z LinkedInu?',
    },
    descriptions: {
      create_responsive_resume:
        'Vytvorte si v priebehu pár minút responzívne CV, ktorý môžete kedykoľvek <b>upraviť</b>,<br class="tw-hidden lg:tw-block" /> <b>zdieľať</b> či <b>vytlačiť</b>.',
      tailored_cv:
        'Koderia CV je vhodný pre všetkých ľudí pracujúcich v IT priemysle vrátane frontend, backend a fullstack developerov, testerov, IT špecialistov, administrátorov či databázistov.',
      understand_experiences:
        'Aké by to bolo vidieť svoje skúsenosti a porozumieť im za pár sekúnd? Práve to umožňuje graf, ktorý vizualizuje vaše dosiahnuté skúsenosti.',
      share_and_print:
        'Zdieľajte svoj CV s každým alebo iba s ľuďmi, pre ktorých je určený. Ak bude treba vytlačte si ho rovnako dokonalý, ako na dispeji.',
      experience_salary: 'CV vám ukáže sumu, ktorú by ste mali zarábať na základe skúseností, ktoré ste uviedli v CV.',
      displayed_on_device: 'CV sa zobrazí bez problémov na akomkoľvek zariadení',
      english_switch: 'Svoj CV môžete jedným klikom prepnúť do angličtiny',
      intended_share: 'Ukážte svoj CV všetkým alebo iba ľuďom, pre ktorých je určený',
      unique_design: 'Jedinečný dizajn CV pre ľudí, ktorí hľadajú prácu v IT odvetví',
      linkedin_import: 'Údaje do CV môžete jednoducho preniesť zo svojho LinkedIn účtu',
      edit_freely: 'Upravte svoj CV kedykoľvek to bude potrebné a z akéhokoľvek zariadenia',
      faq: 'Spísali sme niekoľko odpovedí na otázky, ktoré by vás mohli zaujímať. Ak máte ďalšie, neváhajte nám napísať.',
      faq_1: 'Je. Za vytvorenie, zdieľanie či tlač svojho CV neplatíte vôbec nič. Žiadne reklamy, žiadne skryté poplatky. Nemáte za čo! 😎',
      faq_2:
        'Vaše CV bude prístupné na predvytvorenom odkaze v tvare <b>koderia.sk/cv/id</b>. Tento odkaz môžete poslať komukoľvek.',
      faq_3: 'Je. Tak ako náš web aj CV je dostupné v dvoch jazykoch – anglický a slovenský.',
      faq_4_1: 'Stačí postupovať podľa týchto krokov:',
      faq_4_2: 'Otvorte si podstránku o svojich osobných údajoch v LinkedIne – How LinkedIn uses your data',
      faq_4_3: 'Zvoľte možnosť <b>Download larger data archive</b>.',
      faq_4_4: 'Následne kliknite na <b>Request archive</b>.',
      faq_4_5:
        'Súbor, ktorý si stiahnete rozbaľte a nahrajte do svojho CV súbory <b>Languages</b>, <b>Courses</b>, <b>Positions</b>, <b>Profile</b> a <b>Education</b>. Následne kliknite na <b>Importovať</b>. Berte prosím na vedomie, že LinkedIn vám súbor nemusí poskytnúť ihneď.',
      ratings: 'Čo si o Koderia CV myslia ľudia v IT, ktorí ho už vyskúšali? 🥰',
      start_new_cv: 'Dali sme si záležať na tom, aby každé CV-čko vyzeralo dokonale. Druhý krok už nechávame na vás.',
      private_access: 'CV je buď súkromný, prístupný pre registrovaných užívateľov alebo chránený heslom.',
    },
    actions: {
      create_resume: 'Vytvoriť CV',
      show_sample: 'Ukážka vzorového CV',
    },
    invitation: 'Pozvánka',
  },
  newsletter: {
    subtitles: {
      android_ios_projects: 'Najčerstvejšie Android a iOS projekty do vašej schránky',
      personalized_jobs: 'Personalizované pracovné ponuky do vašej schránky',
    },
    descriptions: {
      send_personalized_projects: 'Do schránky vám pošleme iba personalizované projekty, ktoré sa zhodujú s vašim zameraním.',
      no_spam: 'Nikdy nie spam',
      koderia_newsletter: 'V Koderia Newsletteri nájdete len tie pracovné ponuky, ktoré vás naozaj zaujímajú. Nikdy nie spam.',
    },
  },
  promo: {
    title: 'projekty pre celé tímy (35+ ľudí)',
    description:
      'Vytvárame nové tímy <span class="tw-font-bold">programátorov</span>, <span class="tw-font-bold">testerov</span> a <span class="tw-font-bold">scrum masterov</span> pre nové projekty z bankového sektora. Pracujte z domu pre etablovaného klienta...',
  },
  meta: {
    auth_sign_in: 'Prihlásiť sa',
    auth_forgot_password: 'Zabudnuté heslo',
    auth_create_account: 'Vytvoriť účet',
    work: 'Práca',
    events: 'Podujatia',
    blog: 'Blog',
    newsfeed: 'Newsfeed',
    dictionary: 'Slovník',
    what_is_koderia: 'Čo je Koderia?',
    adequate_salary: 'Adekvátny plat',
    adequate_salary_thank_you: 'Ďakujeme - Adekvátny plat',
    calculator: 'Mzdová kalkulačka',
    privacy_policy: 'Zásady ochrany osobných údajov',
    resume: 'CV',
    about_us: 'O nás',
    extra: 'Extra',
    newsletter: 'Newsletter',
    promo: 'Nové remote projekty pre celé tímy (35+ ľudí)',
    referral: 'Referral',
    referral_consent: 'Súhlas - Referral',
    unsubscribe: 'Unsubscribe',
    account: 'Účet',
    account_saved: 'Uložené - Účet',
    account_added: 'Pridané - Účet',
    my_resume: 'Môj CV',
    registration_completion: 'Dokončenie registrácie',
    work_types_comparison: 'Porovnanie foriem práce',
    frame_help_bratislava: 'Pomôž Bratislave v budovaní nového tímu IT špecialistov',
    frame_game_industry: 'Pracuj v hernom priemysle',
    frame_net_teams: 'Nové pracovné pozície pre .NET tímy',
    404: 'Stránka nebola nájdená',
  },
  route_alias: {
    auth_sign_in: 'prihlasit-sa',
    auth_forgot_password: 'zabudnute-heslo',
    auth_sign_up: 'registracia',
    project_index: 'praca',
    project_detail: 'praca/:id',
    event_index: 'podujatia',
    event_detail: 'podujatia/:id',
    blog_index: 'blog',
    blog_detail: 'blog/:slug',
    newsfeed_index: 'newsfeed',
    newsfeed_detail: 'newsfeed/:id',
    calculator: 'mzdova-kalkulacka',
    cv_landing: 'cv',
    cv_detail: 'cv/:id',
    extra: 'extra',
    koderia_about: 'koderia',
    about_us: 'o-nas',
    newsletter: 'newsletter',
    newsletter_mobile: 'newsletter-mobile',
    promo_index: 'nove-projekty',
    questionaire: 'adekvatny-plat',
    questionaire_thank_you: 'adekvatny-plat/dakujeme',
    questionaire_method: 'adekvatny-plat/:queryProfession',
    questionaire_programmer_form: 'adekvatny-plat/:programmer/formular',
    questionaire_tester_form: 'adekvatny-plat/tester/formular',
    questionaire_cv: 'adekvatny-plat/:queryProfession/cv',
    referral: 'referral',
    referral_form: 'referral/formular',
    referral_form_project: 'referral/formular/:id',
    referral_consent: 'referral/suhlas/:id',
    privacy_policy: 'ochrana-osobnych-udajov',
    unsubscribe_index: 'unsubscribe',
    profile: 'profil',
    profile_saved: 'profil/ulozene',
    profile_added: 'profil/pridane',
    profile_cv: 'profil/cv',
    finish_signup: 'registracia/dokoncenie',
    work_types_comparison: 'porovnanie-foriem-prace',
    frame_town: 'MestoBratislava',
    frame_game_industry: 'pracuj-v-hernom-priemysle',
    frame_net: 'net-projekty',
  },
}

export default sk
