import axios from 'axios'
import config from '@shared/config'
import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import i18n from '@web/assets/i18n'

const api = axios.create({
  baseURL: config.apiUrl,
})

axios.interceptors.request.use(
  (config) => config,
  (e) => {
    Sentry.captureException(e, {
      level: 'error',
      extra: {
        request: {
          url: e.config?.url,
          method: e.config?.method,
          headers: e.config?.headers,
          data: e.config?.data,
        },
      },
    })
    return Promise.reject(e)
  },
)

api.interceptors.response.use(
  (response) => response,
  (e) => {
    if (e.response?.status !== 403) {
      Sentry.captureException(e, {
        fingerprint: [
          'axios',
          e.response?.status?.toString(),
          e.response?.config?.url,
        ],
      })
    }

    if (e.response?.status === 500) {
      Vue.$toast.error(i18n.t('global.errors.unexpected_error'))
    }

    return Promise.reject(e)
  },
)

function setBearerToken(token) {
  api.defaults.headers['Authorization'] = `Bearer ${ token }`
}

function clearBearerToken() {
  api.defaults.headers['Authorization'] = undefined
}

export { api, setBearerToken, clearBearerToken }
