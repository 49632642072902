export const project1 = {
  id: 'software-engineer-gen-ai',
  name: 'Junior Software Engineer with focus on GenAI',
  description: `
      <p>Si v poslednom ročníku univerzity alebo čerstvý absolvent s nadšením pre AI? Chceš sa ponoriť dosveta generatívnej umelej inteligencie a získať praktické skúsenosti? Poď k nám a my ťa to naučíme!</p>

      <p><strong>Tvoja náplň práce bude:</strong></p>
      <ul>
        <li>Prieskum AI platforiem ako N8N, Relevance AI a hľadanie ich najlepšieho využitia.</li>
        <li>Implementácia AI agentov, ktorí dokážu riešiť reálne problémy.</li>
        <li>Prompt engineering – ako efektívne komunikovať s LLM modelmi.</li>
        <li>Praktické využitie LLM v rôznych biznisových prípadoch.</li>
        <li>Porovnávanie rôznych LLM modelov a ich aplikovateľnosť v praxi.</li>
      </ul>

      <p><strong>Čo od teba očakávame?</strong></p>
      <ul>
        <li>Chuť učiť sa a rozmýšľať nad riešeniami.</li>
        <li>Záujem o AI a jej možnosti.</li>
        <li>
          Výhodou, nie podmienkou, je znalosť:
          <ul>
            <li>Python alebo JavaScript/ TypeScript.</li>
            <li>Základných algoritmov a návrhových vzorov.</li>
            <li>Všeobecných software engineering princípov.</li>
          </ul>
        </li>
        <li>Angličtina na úrovni fluent – bez nej to nepôjde.</li>
      </ul>

      <p><strong>Prečo si vybrať nás?</strong></p>
      <ul>
        <li>Získaš skúsenosti s komunikáciou v rôznych sektoroch a s firmami rozličnej veľkosti</li>
        <li>Môžeš sa tešiť na podporu od skúseného tímu a na prácu s modernými nástrojmi, ktoré ti uľahčia prácu</li>
        <li>Tvoja kariéra bude mať jasný smer – otvoríme ti dvere do sveta IT a predaja</li>
      </ul>

      <p><strong>Zamestnanecké výhody, benefity</strong></p>
      <ul>
        <li>Príležitosť rásť a učiť sa od skúsených kolegov.</li>
        <li>Flexibilný part-time úväzok (popri škole).</li>
        <li>Prostredie, kde tvoj názor má váhu a môžeš experimentovať.</li>
      </ul>

      <p><strong>Platové podmienky</strong></p>
      <p>8 € / hod</p>
  `,
  language: 'Anglický (B2) a Slovenský',
  city: 'Bratislava',
  partner: null,
  profession: null,
  requiredLevels: [],
  states: [
    {
      value: 'PUBLISHED',
      displayString: 'Published',
    },
  ],
  expertises: [],
  tags: [],
  legalRelationType: null,
  remoteOfficePercentage: null,
  hourlyRate: 8,
  mdBudgetFrom: null,
  mdBudgetTo: null,
  monthlySalaryFrom: null,
  monthlySalaryTo: null,
  publishedAt: '2025-01-24',
  jobStartDate: 'ASAP',
  projectLengthInMonths: null,
  responsibleHR: {
    name: 'Kristína Petreková',
    email: 'petrekova@objectify.sk',
  },
}

export const careerProjects = [project1]
