import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'

import { getCurrentUser } from '../firebase'
import store from '../store'
import routes, { ROUTE_NAMES } from './routes'
import i18n from '@web/assets/i18n'
import { getCurrentLocale, getDefaultLocale, isCurrentLocaleDefault } from '@web/assets/i18n/helper'

/**
 * The routerPushHandler object is a Proxy handler designed to augment the behavior of the 'push' method
 * used in routing libraries, commonly in web applications, to navigate between pages.
 * It ensures certain parameters are present and handles specific navigation errors gracefully.
 *
 * The handler modifies the method it wraps by checking the presence of 'params' in the first argument
 * passed to it.
 * If 'params' is missing, it initializes it as an empty object.
 * Furthermore, it checks for a 'locale' within 'params' and assigns it the value of the current locale obtained from
 * `getCurrentLocale()` or defaults to `getDefaultLocale()` if not found.
 *
 * Error handling is implemented to silently catch and ignore certain types of navigation-related errors,
 * specifically errors indicating a redundant navigation or those resulting from navigation guards.
 * Other errors are thrown as usual.
 *
 * This handler is particularly useful for internationalized applications where locale maintenance is
 * crucial, and where navigation errors resulting from redirect attempts or redundant navigations need
 * to be suppressed to enhance user experience.
 */
const routerPushHandler = {
  apply: async (target, thisArg, argumentsList) => {
    try {
      const argument = argumentsList[0]

      if (argument && !argument.name) {
        throw new Error('The name of the route is missing.')
      }

      if (!('params' in argument)) {
        argument.params = {}
      }

      if (!argument.params.locale) {
        argument.params.locale = getCurrentLocale() || getDefaultLocale()
      }

      return await target.apply(thisArg, argumentsList)
    } catch (error) {
      // Catch errors and do nothing
      const isNavigationGuardError = String(error.message).toLocaleLowerCase().indexOf('redirected when going from') !== -1
      const isRedundantNavigationError = String(error.message).toLocaleLowerCase().indexOf('redundant navigation') !== -1

      if (isRedundantNavigationError || isNavigationGuardError) {
        return
      }

      const argument = argumentsList.length > 0 ? argumentsList[0] : {}
      const isCanceledNavigationError = String(error.message).toLocaleLowerCase().indexOf('navigation cancelled') !== -1

      if (isCanceledNavigationError && argument.name === ROUTE_NAMES.PROFILE_CV) {
        return
      }

      throw error
    }
  },
}
VueRouter.prototype.push = new Proxy(VueRouter.prototype.push, routerPushHandler)
VueRouter.prototype.replace = new Proxy(VueRouter.prototype.replace, routerPushHandler)
Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history',
})

/**
 * Code stolen from https://alligator.io/vuejs/vue-router-modify-head
 * If there's a bug, don't blame me :)
 */
const routerBeforeMeta = async to => {
  const locale = getCurrentLocale()
  const html = document.documentElement

  html.setAttribute('lang', locale)
  i18n.locale = locale

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags)

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title ? `${ i18n.t(nearestWithTitle.meta.title) } – Koderia.sk` : 'Koderia.sk'
  else document.title = 'Koderia.sk'

  Array.from(document.querySelectorAll('[data-vue-index-controlled]')).map(el => el.parentNode.removeChild(el))
  if (!nearestWithMeta) return
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement('meta')
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })
      tag.setAttribute('data-vue-index-controlled', '')
      return tag
    })
    .forEach(tag => document.head.appendChild(tag))
}

const routerBeforeAppbar = async to => {
  const record =
    to.matched
      .slice()
      .reverse()
      .find(r => r.meta && r.meta.appBarStyle) || {}
  const { appBarStyle } = record.meta || {}

  store.dispatch('APPBAR_STYLE', appBarStyle)
}

router.beforeEach(async (to, from, next) => {
  const { name, params, matched } = to
  const { locale } = params

  routerBeforeMeta(to)
  routerBeforeAppbar(to)

  const requiresAuth = matched.some(record => record.meta.requiresAuth)
  const requiresGuest = matched.some(record => record.meta.requiresGuest)

  const currentUser = await getCurrentUser()

  if (to.meta.redirectIfNotDefaultLanguage && !isCurrentLocaleDefault()) {
    next({ name: ROUTE_NAMES.HOME, params: { locale: getCurrentLocale() } })
  } else if (requiresAuth && !currentUser) {
    const in30Minutes = 1 / 48
    const redirectTo = Cookies.get('redirect.after_login')

    if (!redirectTo) {
      Cookies.set('redirect.after_login', name, { expires: in30Minutes })
    }

    next({ name: ROUTE_NAMES.SIGN_IN, params: { locale } })
  } else if (requiresAuth && currentUser && !currentUser.emailVerified) {
    routerBeforeMeta(from)
    routerBeforeAppbar(from)

    store.commit('setDialog', { name: 'verification', value: true })

    next(from)
  } else if (requiresGuest && currentUser) {
    next({ name: ROUTE_NAMES.HOME, params })
  } else {
    next()
  }
})

export default router
