import 'vue-toast-notification/dist/theme-bootstrap.css'
import './tailwind.css'

import VueToast from 'vue-toast-notification'

import config from '@shared/config'

import './firebase'
import './sentry'

import Vue from 'vue'
import vuetify from './plugins/vuetify'
import {
  default as CookieConsent,
  en as cookieConsentEnTranslations,
  sk as cookieConsentSkTranslations,
} from './plugins/CookieConsent'
import router from './router'
import store from './store.js'

import { formatDate, formatDateDistance, formatDateRelative, formatDateRelativeSimple } from './utils/date'
/*
 * import app modules
 */
import './components'
import App from './App.vue'
import './home'
import './projects'
import './questionaire'
import './enums'
import './referral'
import './events'
import './blog'
import './extra'
import './auth'
import './user'
import './newsfeed'

import './postsStore'
import i18n from '@web/assets/i18n'
import { localeServiceMixin } from '@web/assets/i18n/helper'
import { ROUTE_NAMES } from '@web/router/routes'

store.syncWithReouter(router)

Vue.config.productionTip = config.env !== 'production'

Vue.filter('formatDate', formatDate)
Vue.filter('formatDateRelative', formatDateRelative)
Vue.filter('formatDateRelativeSimple', formatDateRelativeSimple)
Vue.filter('formatDateDistance', formatDateDistance)

Vue.filter('timeDiff', function (time) {
  const t = new Date(time).getTime()
  let now = new Date().getTime()
  let diff = ((now - t) / 60000).toFixed(0)
  if (diff < 2) return `Pred 1 minútou`
  if (diff < 60) return `Pred ${ diff } minútami`
  if (diff < 120) return `Pred 1 hodinou`
  if (diff < 1440) return `Pred ${ (diff / 60).toFixed(0) } hodinami`
  if (diff >= 1440 && diff < 2880) return `Pred 1 dňom`
  return `Pred ${ (diff / 1440).toFixed(0) } dňami`
})

Vue.filter('truncate', function (value, maxLength = 65) {
  if (!value) return ''
  const v = String(value)
  if (v.length > maxLength) {
    return v.substring(0, maxLength - 1).trim() + '…'
  }
  return v
})

Vue.config.ignoredElements = ['x-frame-bypass']

Vue.use(CookieConsent, {
  revision: 0,
  cookie: {
    name: 'cc_cookie',
  },
  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
    analytics: {
      enabled: false,
    },
  },

  language: {
    default: 'en',
    translations: {
      en: cookieConsentEnTranslations,
      sk: cookieConsentSkTranslations,
    },
  },

  onConsent: function ({ cookie }) {
    if (cookie && cookie.categories && cookie.categories.includes('analytics')) {
      if (window.gtag !== undefined) {
        window.gtag('consent', 'update', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
          ad_user_data: 'granted',
          ad_personalization: 'granted',
        })
      }
    }
  },
})
Vue.use(VueToast, {
  position: 'top-right',
})

Vue.mixin({
  data: function () {
    return {
      $ROUTE_NAMES: ROUTE_NAMES,
    }
  },
  computed: localeServiceMixin.computed,
  methods: localeServiceMixin.methods,
})

/*
 * init app
 */
new Vue({
  i18n,
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
