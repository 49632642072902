const lowerRatedTechnologies = ['CSS', 'HTML5', 'JSON']
const radarLowerRatedTechnologies = ['Bamboo', 'Apache Camel', 'Flexbox', 'Selenium', 'Ubuntu', 'Apache OpenWhisk', 'Bash', 'Eclipse Mojarra', 'Apache Mesos', 'Expo', 'Spock', 'JUnit', 'Vercel', 'Maven', 'SoapUI', 'RHEL', 'Babel', 'NetBSD', 'JaCoCo', 'SASS', 'Ratpack', 'Munin', 'SLF4J', 'OpenStack', 'Debian', 'Monit', 'New Relic', 'IIS', 'Apache Freemarker', 'JSON', 'CSS', 'jQuery', 'Puppet', 'Eclipse Link', 'Sonar', 'Lombok', 'XRAY', 'JavaScript', 'Mocha', 'Apache Hadoop', 'Apache JMeter', 'HTML5', 'Log4J 2', 'Jest', 'Sass', 'Apache MyFaces', 'TestNG', 'JMockit', 'Team City', 'Fedora Workstation', 'AssertJ', 'Nexus', 'WebSocket', 'Postman', 'Apache Shiro', 'Next.js', 'Papertrail', 'CentOS', 'RabbitMQ', 'Apache HTTPD', 'Graylog', 'npm', 'Circle CI', 'Yarn', 'Git', 'Katalon', 'Apache Struts', 'Nagios', 'OmniFaces', 'SUSE Linux', 'CSS Grid', 'XML', 'MapStruct', 'MyBatis', 'Apache Subversion', 'PagerDuty', 'Graphite', 'Instana', 'PowerMock', 'OpenBSD', 'Amazon EC2', 'Netflix Eureka', 'Apache Wicket', 'FreeBSD', 'Memcached', 'Prometheus', 'Linux', 'Apache Flink', 'Zabbix', 'Apache CXF', 'LogBack', 'Mockito', 'Flyway', 'SOAP', 'Less', 'JSON Web Token', 'Webpack', 'Apache ActiveMQ', 'AJAX']
const higherRatedTechnologies = [
  {
    name: 'AWS',
    modifier: 30,
  },
  {
    name: 'ETL',
    modifier: 10,
  },
  {
    name: 'GraphQL',
    modifier: 10,
  },
  {
    name: 'Kotlin',
    modifier: 20,
  },
  {
    name: 'Liferay',
    modifier: 15,
  },
  {
    name: 'Microsoft Azure',
    modifier: 20,
  },
  {
    name: 'Perl',
    modifier: 20,
  },
  {
    name: 'MongoDB',
    modifier: 10,
  },
  {
    name: 'Python',
    modifier: 10,
  },
  {
    name: 'RxJava2',
    modifier: 15,
  },
  {
    name: 'RxJS',
    modifier: 15,
  },
]

const FEtechnologies = ['Angular 1', 'Angular 2+', 'VueJS', 'ReactJS', 'JavaScript']
const BEtechnologies = ['Java', '.NET', 'ASP.NET', 'Node.js']

const lowerRatedTechnologiesExperienceWage = [60, 90, 90, 120, 140, 180, 210]
const regularlyRatedTechnologiesExperienceWage = [100, 120, 140, 180, 210, 230, 240]
const manualTesterWage = [60, 70, 100, 100, 120, 150, 180]
const autoTesterWage = [100, 140, 160, 160, 200, 220, 240]

const programmerCalculator = {
  isInputValid(programmerObject) {
    if (programmerObject.name == undefined || programmerObject.name == '') throw 'Name is not set propperly.'
    if (programmerObject.email == undefined || programmerObject.email == '') throw 'Email is not set propperly.'
    if (programmerObject.overalPraxis == undefined || programmerObject.overalPraxis < 0 || programmerObject.overalPraxis > 7)
      throw `Overal Praxis is not set propperly. ${ programmerObject.overalPraxis }`
    if (programmerObject.isTeamleader == undefined) throw 'isTeamleader is not set propperly.'
    if (programmerObject.isArchitect == undefined) throw 'isArchitect is not set propperly.'
    if (programmerObject.workedDomain == undefined) throw 'workedDomain is not set propperly.'
    if (!programmerObject.gdpr.gdprValue) throw 'Programmer did not agree to have his data processed.'

    return true
  },
  processInput(programmerObject) {
    programmerObject.technologies = []
    let technologiesKnown = programmerObject.technologiesKnown // array of technologies known by a programmer in a string format
    let technologiesPraxis = programmerObject.technologiesPraxis // an array of technologies with information about programmer's prax in the respective technology

    technologiesKnown.forEach(knownTechnology => {
      let matchedTechnology = technologiesPraxis.filter(praxTechnology => praxTechnology.name == knownTechnology)[0]
      programmerObject.technologies.push(matchedTechnology)
    })
  },
  computeWage(programmerObject, useRadar = false) {
    // initialize wage
    programmerObject.wage = 0

    // consider Teamleader trait
    if (programmerObject.overalPraxis > 3 && programmerObject.isTeamleader) programmerObject.wage += 20

    // consider Architect trait + prax > 5 years
    if (programmerObject.technologies.length != 0) {
      let technologiesMaxPraxis = programmerObject.technologies.reduce((a, b) => Math.max(a.praxis, b.praxis))
      if (technologiesMaxPraxis > programmerObject.overalPraxis) programmerObject.overalPraxis = technologiesMaxPraxis
      if (programmerObject.isArchitect && programmerObject.overalPraxis > 4) programmerObject.wage += 30

      // consider Fullstack trait
      let fullstackBe = false
      let fullstackFe = false
      programmerObject.technologies.forEach(tech => {
        if (FEtechnologies.includes(tech.name) && tech.praxis >= 1) fullstackFe = true
        if (BEtechnologies.includes(tech.name) && tech.praxis >= 1) fullstackBe = true
      })
      if (fullstackBe && fullstackFe) {
        programmerObject.wage += 20
      }

      // compute the wage of all three categories of technologies
      let lowerRatedTechnologiesPraxis = -1
      let regularlyRatedTechnologiesPraxis = -1
      let userHigherRatedTechnologies = []

      programmerObject.technologies.forEach(tech => {
        if ((useRadar ? radarLowerRatedTechnologies : lowerRatedTechnologies).includes(tech.name)) {
          if (lowerRatedTechnologiesPraxis < tech.praxis) lowerRatedTechnologiesPraxis = tech.praxis
        } else {
          if (regularlyRatedTechnologiesPraxis < tech.praxis) regularlyRatedTechnologiesPraxis = tech.praxis

        }
        higherRatedTechnologies.forEach(hrTech => {
          if (hrTech.name == tech.name && tech.praxis >= 1) userHigherRatedTechnologies.push(hrTech)
        })
      })
      let lowerRatedTechnologiesWage = lowerRatedTechnologiesPraxis >= 0 ? lowerRatedTechnologiesExperienceWage[lowerRatedTechnologiesPraxis] : 0
      let regurarlyRatedTechnologiesWage =
        regularlyRatedTechnologiesPraxis >= 0 ? regularlyRatedTechnologiesExperienceWage[regularlyRatedTechnologiesPraxis] : 0

      programmerObject.wage += Math.max(lowerRatedTechnologiesWage, regurarlyRatedTechnologiesWage)
      if (userHigherRatedTechnologies.length > 0) {
        userHigherRatedTechnologies = userHigherRatedTechnologies.sort((a, b) => b.modifier - a.modifier)
        programmerObject.wage += userHigherRatedTechnologies.shift()?.modifier || 0
        programmerObject.wage += userHigherRatedTechnologies.shift()?.modifier || 0
      }
      if (programmerObject.wage > 350) programmerObject.wage = 350
    }
  },
}

const testerCalculator = {
  isInputValid(testerObject) {
    if (testerObject.name == undefined || testerObject.name == '') throw 'Name is not set propperly.'
    if (testerObject.email == undefined || testerObject.email == '') throw 'Email is not set propperly.'
    if (testerObject.overalPraxis == undefined || testerObject.overalPraxis < 0 || testerObject.overalPraxis > 7)
      throw `Overal Praxis is not set propperly. ${ testerObject.overalPraxis }`
    if (!testerObject.gdpr.gdprValue) throw 'Programmer did not agree to have his data processed.'

    return true
  },

  computeWage(testerObject) {
    // initialize wage
    testerObject.wage = 0

    // consider traits
    if (!testerObject.manualTester) {
      // consider CI trait
      if (testerObject.CIexperience && testerObject.overalPraxis >= 2) testerObject.wage += 20
      // consider Performance testing trait
      if (testerObject.performanceExperience && testerObject.overalPraxis >= 3) testerObject.wage += 80
    }

    // compute wage
    testerObject.wage += testerObject.manualTester ? manualTesterWage[testerObject.overalPraxis] : autoTesterWage[testerObject.overalPraxis]
  },
}

export {
  programmerCalculator,
  testerCalculator,
}

