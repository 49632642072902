import i18n, { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '@web/assets/i18n/index'
import router from '@web/router'
import store from '@web/store'
import { ROUTE_NAMES } from '@web/router/routes'

export const localeServiceMixin = {
  computed: {
    defaultLocale() {
      return i18nService.defaultLocale
    },
    currentLocale() {
      return i18nService.currentLocale
    },
    isCurrentLocaleDefault() {
      return i18nService.currentLocale === i18nService.defaultLocale
    },
  },
  methods: {
    switchLocale(locale) {
      switchLocale(locale)
    },
  },
}

export const routeLocaleMiddleware = (to, _from, next) => {
  const paramLocale = to.params.locale

  if (!i18nService.isLocaleSupported(paramLocale)) {
    return next({
      ...to,
      params: {
        ...to.params,
        locale: guessLocale(),
      },
    })
  }

  if (i18nService.currentLocale !== paramLocale) {
    i18nService.currentLocale = paramLocale
  }

  return next()
}

export const getDefaultLocale = () => i18nService.defaultLocale
export const getCurrentLocale = () => i18nService.currentLocale
export const isCurrentLocaleDefault = () => i18nService.currentLocale === i18nService.defaultLocale
export const switchLocale = locale => {
  if (!i18nService.isLocaleSupported(locale)) {
    return
  }

  i18nService.currentLocale = locale
  localeStorageService.saveUserPreferredLocale(locale)

  router.onReady(() => {
    const currentParams = router.currentRoute.params || {}
    const currentQuery = router.currentRoute.query || {}

    if ([ROUTE_NAMES.SIGN_IN, ROUTE_NAMES.SIGN_UP].includes(router.currentRoute.name)) {
      return
    }

    router.replace({
      name: router.currentRoute.name,
      params: { ...currentParams, locale: i18nService.currentLocale },
      query: { ...currentQuery },
    })
  })
}

const i18nService = {
  get defaultLocale() {
    return DEFAULT_LOCALE
  },
  get currentLocale() {
    return i18n.locale
  },
  set currentLocale(locale) {
    i18n.locale = locale
  },
  isLocaleSupported(locale) {
    return SUPPORTED_LOCALES.includes(locale)
  },
}

const localeStorageService = {
  saveUserPreferredLocale(locale) {
    localStorage.setItem('user_preferred_locale', locale)
  },
  getUserPreferredLocale() {
    return localStorage.getItem('user_preferred_locale')
  },
}

function guessLocale() {
  const userLocale = store.getters['USER/getUserAppLocale']

  if (userLocale && i18nService.isLocaleSupported(userLocale)) {
    return userLocale
  }

  const userPreferredLocale = localeStorageService.getUserPreferredLocale()

  if (userPreferredLocale && i18nService.isLocaleSupported(userPreferredLocale)) {
    return userPreferredLocale
  }

  const browserLocale = getLocaleFromBrowser()

  if (i18nService.isLocaleSupported(browserLocale)) {
    return browserLocale
  }

  return i18nService.defaultLocale
}

function getLocaleFromBrowser() {
  const locale = SUPPORTED_LOCALES.find(sl =>
    navigator.language.includes(sl),
  ) || SUPPORTED_LOCALES.find(sl =>
    navigator.languages.some(lang => lang.includes(sl)),
  )

  return locale || DEFAULT_LOCALE
}
