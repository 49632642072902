import Vue from 'vue'
import i18n from '@web/assets/i18n'

export const handleToast = (error, callerName) => {
  if (error.response?.status === 500) {
    return
  }

  if (error.response?.status === 404 && callerName) {
    Vue.$toast.error(i18n.t('global.errors.error_404', { name: callerName }))
  } else {
    Vue.$toast.error(error.message)
  }
}
