import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import config from '@shared/config'
import router from '@web/router'
import { version } from '../package.json'
import { isAxiosError } from 'axios'

Sentry.init({
  Vue,
  dsn: config.sentry.dsn,
  environment: config.sentry.environment,
  release: `koderia-web@${ version }-${ config.sentry.environment }`,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  beforeSend: handleBeforeSent,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: config.sentry.replaysSessionSampleRate,
  replaysOnErrorSampleRate: config.sentry.replaysOnErrorSampleRate,
})

/**
 * @typedef {import('@sentry/types').EventHint} EventHint
 *
 * @param {ErrorEvent} event
 * @param {EventHint} hint
 */
function handleBeforeSent(event, hint) {
  if (isAxiosError(hint?.originalException)) {
    if (hint?.originalException?.response) {
      event.contexts = {
        ...event.contexts,
        Axios: {
          Response: hint.originalException.response,
        },
      }
    }
  }

  return event
}
