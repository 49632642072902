import router from '@web/router'
import Cookies from 'js-cookie'
import { ROUTE_NAMES } from '@web/router/routes'
import { getCurrentLocale } from '@web/assets/i18n/helper'

export function redirectToOrigin() {
  router
    .push({
      name: ROUTE_NAMES.HOME,
    })
    .catch(() => {
    })
}

export function redirectAfterLogin() {
  const redirectTo = Cookies.get('redirect.after_login')
  console.log(redirectTo)

  if (redirectTo) {
    setTimeout(() => {
      Cookies.remove('redirect.after_login')
    }, 250)

    const locale = getCurrentLocale()

    try {
      const parsed = JSON.parse(redirectTo)

      if (!parsed.params) {
        parsed.params = {}
      }

      parsed.params.locale = locale

      router.push(parsed)
    } catch {
      // Try parse `redirect.after_login` cookie, if not an object then it should be a route name
      router.push({
        name: redirectTo,
        params: { locale },
      })
    }
  } else {
    redirectToOrigin()
  }
}

export function redirectNewUserToFinishSignUp() {
  const { name: routeName } = router.currentRoute

  if (routeName !== ROUTE_NAMES.FINISH_SIGNUP) {
    const in30Minutes = 1 / 48
    const redirectToAfterLogin = Cookies.get('redirect.after_login')

    if (redirectToAfterLogin) {
      Cookies.remove('redirect.after_login')
      Cookies.set('redirect.after_finish-signup', redirectToAfterLogin, { expires: in30Minutes })
    } else {
      Cookies.set('redirect.after_finish-signup', routeName, { expires: in30Minutes })
    }

    router.push({ name: ROUTE_NAMES.FINISH_SIGNUP })
  }
}

export function redirectAfterSuccessfulAfterSignUp() {
  const redirectTo = Cookies.get('redirect.after_finish-signup')
  console.log(redirectTo)

  if (redirectTo && ![ROUTE_NAMES.FINISH_SIGNUP, ROUTE_NAMES.SIGN_IN, ROUTE_NAMES.SIGN_UP].includes(redirectTo)) {
    Cookies.remove('redirect.after_finish-signup')

    try {
      const routeObj = JSON.parse(redirectTo)

      router.push(routeObj)
    } catch {
      router.push({ name: redirectTo })
    }
  } else {
    router.push({ name: ROUTE_NAMES.HOME })
  }
}

export function redirectUserToVerificationPage() {
  router.push({ name: 'verification' })
}
